import { all } from 'redux-saga/effects';
import { localeSaga } from 'store/locale/saga';
import { userSaga } from 'store/user/saga';
import { utilSaga } from 'store/util/saga';
import { gameSaga } from 'store/game/saga';
import { OTBTournamentSaga } from 'store/otbTournament/saga';
import { connectGameWs } from 'store/gameWs/sagaWs';

export default function* rootSaga() {
    yield all([
        userSaga(),
        localeSaga(),
        utilSaga(),
        gameSaga(),
        OTBTournamentSaga(),
        connectGameWs(),
    ]);
}
