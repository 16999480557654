export const SEND_WS = {
    NEW_GAME: 'new_game',
    CANCEL_WAITING: 'cancel_waiting',
    MOVE: 'move',
    BEST_MOVES: 'best_moves',
    RESIGN: 'resign',
    DRAW_OFFER: 'draw_offer',
    DRAW_OFFER_RESPONSE: 'draw_offer_resp',
    SEARCH_USER: 'search_user',
    NEW_GAME_INVITE_RESPONSE: 'new_game_invite_resp',
    CONNECT: 'connect_game',
    SPECTATE_OTB_GAME: 'otb_connect',
    DISCONNECT_OTB_GAME: 'otb_disconnect',
    CANCEL_CHALLENGE: 'new_game_invite_cancel',
};

export const RECEIVE_WS = {
    MOVE: 'move',
    MATCHED: 'matched',
    GAME_OVER: 'game_over',
    DRAW_OFFER: 'draw_offer',
    USER_SEARCH_RESULT: 'search_result',
    NEW_GAME_INVITE: 'new_game_invite',
    CONNECT: 'connect',
    SPECTATE_OTB_GAME: 'connect_otb',
    OTB_MOVE: 'otb_move',
    OTB_GAME_OVER: 'otb_game_over',
    NEW_GAME_INVITE_RESPONSE: 'new_game_invite_resp',
};

export const DRAW_OFFER_RESPONSE = {
    ACCEPT: 'accept',
    DENY: 'deny',
};
