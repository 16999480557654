import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});

if (localStorage.getItem('_k_sqr_at')) {
    instance.defaults.headers.common = {'Authorization': `bearer ${localStorage.getItem('_k_sqr_at')}`};
}
export function setAccessToken(at) {
    instance.defaults.headers.common = {'Authorization': `bearer ${at}`};
}

// If response status 401 (forbiden) remove item from localStorage (logout user)
instance.interceptors.response.use(
    (response) => {
        if (response.data.error) {
            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('_k_sqr_at');
            localStorage.removeItem('_k_sqr_rt');
            localStorage.removeItem('_k_sqr_ws_t');
            window.location.replace('/onboarding');
        }
        return Promise.reject(error);
    }
);

export default instance;
