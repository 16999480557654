import API from 'API';
import { auth } from 'shared/utility';

const API_LIST = {
    TOKEN_REFRESH: '/users/token_refresh',
    LOGIN_REQUEST: '/users/login',
    FACEBOOK_LOGIN_REQUEST: '/users/facebook',
    GOOGLE_LOGIN_REQUEST: '/users/google',
    REGISTER_REQUEST: '/users/register',
    EMAIL_REGISTER_REQUEST: '/users/register_email',
    USER_REQUEST: '/users/profile',
    USERNAME_CHECK_REQUEST: '/users/check',
    START_PASSWORD_RECOVERY: '/users/start_password_recovery',
    RECOVER_PASSWORD: '/users/recover_password',
    CHAT_UID_REQUEST: '/users/comet_chat_token',
};

export function userRequest(params) {
    return API.get(API_LIST.USER_REQUEST, auth(params));
}

export function checkUserAuth(params) {
    return API.post(API_LIST.TOKEN_REFRESH, params);
}

export function loginRequest(params) {
    return API.post(API_LIST.LOGIN_REQUEST, params);
}

export function registerRequest(params) {
    return API.post(API_LIST.REGISTER_REQUEST, params);
}

export function emailRegisterRequest(params) {
    return API.post(API_LIST.EMAIL_REGISTER_REQUEST, params);
}

export function facebookLoginRequest(params) {
    return API.post(API_LIST.FACEBOOK_LOGIN_REQUEST, params);
}

export function googleLoginRequest(params) {
    return API.post(API_LIST.GOOGLE_LOGIN_REQUEST, params);
}

export function usernameCheckRequest(username) {
    return API.get(`${API_LIST.USERNAME_CHECK_REQUEST}?username=${username}`);
}

export function startPasswordRecovery(params) {
    return API.post(API_LIST.START_PASSWORD_RECOVERY, params);
}

export function recoverPassword(params) {
    return API.post(API_LIST.RECOVER_PASSWORD, params);
}

export function chatAuthTokenRequest(params) {
    return API.get(API_LIST.CHAT_UID_REQUEST, params);
}
