import {
    WS_CONNECTION_REQUEST,
    WS_CONNECTION_FAILURE,
    WS_CONNECTION_SUCCESS,
    WS_DISCONNECT,
    WS_DISCONNECT_SUCCESS,
    WS_UNEXPECTED_DISCONNECT,
    WS_RECEIVE_ACTION,
    WS_SEND_ACTION,
    WS_CLEAR_QUEUE,
} from './actionConstants';

export const wsConnectionSuccess = (socketChannel, name) => {
    return {
        type: WS_CONNECTION_SUCCESS,
        socketChannel,
        name,
    };
};

export const wsConnectionRequest = () => {
    return {
        type: WS_CONNECTION_REQUEST
    };
};

export const wsConnectionFailure = (e) => {
    return {
        type: WS_CONNECTION_FAILURE,
        error: e,
    };
};

export const wsDisconnect = () => {
    return {
        type: WS_DISCONNECT
    };
};

export const wsDisconnectSuccess = () => {
    return {
        type: WS_DISCONNECT_SUCCESS
    };
};

export const wsUnexpectedDisconnect = () => {
    return {
        type: WS_UNEXPECTED_DISCONNECT
    };
};

export const wsClearQueueList = (index) => {
    return {
        type: WS_CLEAR_QUEUE,
        index
    };
};

export const sendWsAction = (wsAction, data) => {
    return {
        type: WS_SEND_ACTION,
        wsAction,
        data,
    };
};

export const receiveWsAction = (data) => {
    return {
        type: WS_RECEIVE_ACTION,
        data,
    };
};
