import * as constants from './constants';

export const setWsToken = (token) => {
    return {
        type: constants.SET_WS_TOKEN,
        token,
    };
};

export const setAccessToken = (token) => {
    return {
        type: constants.SET_ACCESS_TOKEN,
        token,
    };
};

export const setRefreshToken = (token) => {
    return {
        type: constants.SET_REFRESH_TOKEN,
        token,
    };
};

// Refresh token
export const getRefreshTokenRequest = (token) => {
    return {
        type: constants.GET_REFRESH_TOKEN_REQUEST,
        token,
    };
};

export const getRefreshTokenRequestSuccess = (token) => {
    return {
        type: constants.GET_REFRESH_TOKEN_SUCCESS,
        token,
    };
};

export const getRefreshTokenRequestFailure = (token) => {
    return {
        type: constants.GET_REFRESH_TOKEN_FAILURE,
        token,
    };
};

export const setUserType = (user_type) => {
    return {
        type: constants.SET_USER_TYPE,
        user_type,
    };
};

export const getUser = (token) => {
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return {
        type: constants.GET_USER_REQUEST,
        options,
    };
};

export const getUserSuccess = (user) => {
    return {
        type: constants.GET_USER_SUCCESS,
        user,
    };
};

export const getUserFail = (error) => {
    return {
        type: constants.GET_USER_FAILURE,
        error,
    };
};

export const resetUser = () => {
    return {
        type: constants.RESET_USER,
    };
};

export const setGuestUser = () => {
    return {
        type: constants.SET_GUEST_USER,
    };
};

export const logout = () => {
    return {
        type: constants.USER_LOGOUT,
    };
};

export const checkAuth = () => {
    const _k_sqr_rt = localStorage.getItem('_k_sqr_rt');
    const _k_sqr_at = localStorage.getItem('_k_sqr_at');

    // If there is refresh token
    if (_k_sqr_at) {
        return {
            type: constants.CHECK_AUTH,
            refreshToken: _k_sqr_rt,
            accessToken: _k_sqr_at,
        };
    } else {
        return {
            type: constants.CHECK_AUTH_LOGOUT,
        };
    }
};

// LOGIN --------------------------------
export const loginRequest = (data) => {
    return {
        type: constants.LOGIN_REQUEST,
        data,
    };
};

export const loginSuccess = (data) => {
    return {
        type: constants.LOGIN_SUCCESS,
        data,
    };
};

export const loginFailure = (error) => {
    return {
        type: constants.LOGIN_FAILURE,
        error,
    };
};
// --------------------------------------

// REGISTRATION ----------------------------------
export const registerRequest = (data, flag) => {
    return {
        type: constants.REGISTER_REQUEST,
        data,
        flag,
    };
};

export const registerSuccess = (data) => {
    return {
        type: constants.REGISTER_SUCCESS,
        data,
    };
};

export const registerFailure = (error) => {
    return {
        type: constants.REGISTER_FAILURE,
        error,
    };
};
// -----------------------------------------------

// EMAIL REGISTRATION ----------------------------------
export const emailRegisterRequest = (data) => {
    return {
        type: constants.EMAIL_REGISTER_REQUEST,
        data,
    };
};

export const emailRegisterSuccess = (data) => {
    return {
        type: constants.EMAIL_REGISTER_SUCCESS,
        data,
    };
};

export const emailRegisterFailure = (error) => {
    return {
        type: constants.EMAIL_REGISTER_FAILURE,
        error,
    };
};
// -----------------------------------------------

// FACEBOOK LOGIN --------------------------------
export const facebookLoginRequest = (data) => {
    return {
        type: constants.FACEBOOK_LOGIN_REQUEST,
        data,
    };
};

export const facebookLoginSuccess = (data) => {
    return {
        type: constants.FACEBOOK_LOGIN_SUCCESS,
        data,
    };
};

export const facebookLoginFailure = (error) => {
    return {
        type: constants.FACEBOOK_LOGIN_FAILURE,
        error,
        errorType: 'facebook',
    };
};
// -----------------------------------------------

// GOOGLE LOGIN --------------------------------
export const googleLoginRequest = (data) => {
    return {
        type: constants.GOOGLE_LOGIN_REQUEST,
        data,
    };
};

export const googleLoginSuccess = (data) => {
    return {
        type: constants.GOOGLE_LOGIN_SUCCESS,
        data,
    };
};

export const googleLoginFailure = (error) => {
    return {
        type: constants.GOOGLE_LOGIN_FAILURE,
        error,
        errorType: 'google',
    };
};
// --------------------------------------------

// CHECK USERNAME ------------------------------------
export const checkUsernameRequest = (username) => {
    return {
        type: constants.CHECK_USERNAME_REQUEST,
        username,
    };
};

export const checkUsernameSuccess = (data) => {
    return {
        type: constants.CHECK_USERNAME_SUCCESS,
        data,
    };
};

export const checkUsernameFailure = (error) => {
    return {
        type: constants.CHECK_USERNAME_FAILURE,
        error,
    };
};
// -------------------------------------------------

// START PASSWORD RECOVERY ----------------------------
export const startPasswordRecoveryRequest = (data) => {
    return {
        type: constants.START_PASSWORD_RECOVERY_REQUEST,
        data,
    };
};

export const startPasswordRecoverySuccess = (data) => {
    return {
        type: constants.START_PASSWORD_RECOVERY_SUCCESS,
        data,
    };
};

export const startPasswordRecoveryFailure = (error) => {
    return {
        type: constants.START_PASSWORD_RECOVERY_FAILURE,
        error,
    };
};
// -----------------------------------------------------

// START PASSWORD RECOVERY ----------------------------
export const recoverPasswordRequest = (data) => {
    return {
        type: constants.RECOVER_PASSWORD_REQUEST,
        data,
    };
};

export const recoverPasswordSuccess = (data) => {
    return {
        type: constants.RECOVER_PASSWORD_SUCCESS,
        data,
    };
};

export const recoverPasswordFailure = (error) => {
    return {
        type: constants.RECOVER_PASSWORD_FAILURE,
        error,
    };
};
// -----------------------------------------------------

// GET COMETCHAT AUTH TOKEN AND UID ----------------------
export const getChatAuthTokenRequest = (token) => {
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return {
        type: constants.GET_CHAT_AUTH_TOKEN_REQUEST,
        options,
    };
};

export const chatAuthTokenRequestSuccess = (token) => {
    return {
        type: constants.GET_CHAT_AUTH_TOKEN_SUCCESS,
        token,
    };
};
// -------------------------------------------------------
