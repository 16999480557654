export const chessTimeToSeconds = (time) => {
    if (time.minutes !== undefined && time.seconds !== undefined) {
        if (!time.hours) {
            time.hours = 0;
        }
        return time.hours * 3600 + time.minutes * 60 + time.seconds;
    }
    return time;
};

export const secondToMillisecond = (seconds) => {
    return seconds * 1000;
};

export const millisecondsToSeconds = (milli) => {
    return milli / 1000;
};

export const secondsToChessTime = (sec) => {
    const hours   = Math.floor(sec / 3600);
    const minutes = hours > 0 ? Math.floor((sec / 60) % 60) : Math.floor(sec / 60);
    const seconds = Math.round(sec % 60);

    return {
        hours,
        minutes,
        seconds,
    };
};

export const utcToLocalTime = (time) => {
    return new Date(time);
};

export const getFormatedTimeFromJSDate = (date) => {
    return date.getUTCFullYear() + "/" +
        ("0" + (date.getUTCMonth() + 1)).slice(-2) + "/" +
        ("0" + date.getUTCDate()).slice(-2) + " " +
        ("0" + date.getUTCHours()).slice(-2) + ":" +
        ("0" + date.getUTCMinutes()).slice(-2) + ":" +
        ("0" + date.getUTCSeconds()).slice(-2);
};
