import React from 'react';
import { connect } from 'react-redux';
import { connectManually } from 'store/gameWs/sagaWs';

import {
    confirmDiloag,
    gameChallenge,
    gameChallengeTimeout,
} from 'UI/Dialog/ConfirmDialog';

import {
    sendWsAction,
    receiveWsAction,
    wsClearQueueList,
} from 'store/gameWs/actions';
import { DRAW_OFFER_RESPONSE } from 'store/gameWs/wsConstants';
import * as wsGameActions from 'store/gameWs/wsActions';
import { getGameWs } from 'store/gameWs/selector';
import { getUserWSToken } from 'store/user/selector';
import { redirect } from 'store/util/actions';

import { GAME_URLS } from 'shared/urlList';
import { getGameIdLS, getAccessTokenLS } from 'shared/localStorage';

class WSWrapper extends React.Component {
    state = {
        closeAlert: false,
        sender: false,
    };

    componentDidMount() {
        const gameId = getGameIdLS();

        if (gameId) {
            this.reconnect(gameId);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.wsToken !== this.props.wsToken &&
            this.props.wsToken &&
            !this.props.gameWs.connected &&
            getAccessTokenLS()
        ) {
            connectManually();
        }

        if (prevProps.gameWs !== this.props.gameWs) {
            // If socket disconnected try to connect again
            if (
                prevProps.gameWs.disconnected !==
                    this.props.gameWs.disconnected &&
                this.props.gameWs.disconnected
            ) {
                connectManually();
            }
            if (
                this.props.gameWs.disconnected &&
                this.props.gameWs.connectionAttempts !==
                    prevProps.gameWs.connectionAttempts
            ) {
                const timeout = 1000 * this.props.gameWs.connectionAttempts;
                setTimeout(() => {
                    connectManually();
                }, timeout);
            }

            // If socket connected
            if (prevProps.gameWs.connected !== this.props.gameWs.connected) {
                if (
                    this.props.gameWs.connected &&
                    this.props.gameWs.sendListQueue
                ) {
                    this.props.gameWs.sendListQueue.forEach((action, index) => {
                        this.props.dispatch(action);
                        this.props.dispatchClearQueue(index);
                    });
                }
            }
            // If user matched
            if (
                prevProps.gameWs.matched !== this.props.gameWs.matched &&
                this.invited
            ) {
                this.props.dispatchRedirect(GAME_URLS.PLAY_FRIEND);
            }

            // If user has been challenged
            if (
                prevProps.gameWs.challengeList !==
                    this.props.gameWs.challengeList &&
                this.props.gameWs.challengeList.length > 0
            ) {
                this.onNewGameInvite(this.props.gameWs.challengeList);
            }

            if (
                prevProps.gameWs.waitingFriend.timeout !==
                    this.props.gameWs.waitingFriend.timeout &&
                this.state.sender
            ) {
                this.onTimeout();
            }
        }
    }

    friendChallengeAccepted = (id) => {
        this.sendWs(
            wsGameActions.friendChallengeResponse(
                id,
                DRAW_OFFER_RESPONSE.ACCEPT
            )
        );
    };

    friendChallengeRefused = (id) => {
        this.sendWs(
            wsGameActions.friendChallengeResponse(id, DRAW_OFFER_RESPONSE.DENY)
        );
    };

    reconnect = (gameId) => {
        this.sendWs(wsGameActions.reconnect(gameId));
    };

    sendWs = ({ type, data }) => {
        this.props.dispatchSendWsAction(type, data);
    };

    onTimeout = () => {
        const message = `Challenge invitation timeout`;
        gameChallengeTimeout(message, {
            onClose: () => {
                this.props.dispatchRedirect(GAME_URLS.PLAY_FRIEND);
            },
        });
        this.setState({ sender: false });
    };

    onNewGameInvite = (data) => {
        const challenge = data[data.length - 1];
        const message = `${challenge.user.username} has challenged you to a ${challenge.minutes}:${challenge.seconds} + ${challenge.increment} seconds increment game`;

        gameChallenge(message, {
            yesCb: () => {
                this.invited = true;
                this.friendChallengeAccepted(challenge.user.id);
            },
            noCb: () => this.friendChallengeRefused(challenge.user.id),
        });

        this.setState({ sender: true });
    };

    onConnect(message) {
        const data = message.data;
        if (
            !data.ended &&
            window.location.href.indexOf(GAME_URLS.PLAY_FRIEND) === -1 &&
            window.location.href.indexOf(GAME_URLS.PLAY_OPPONENT) === -1
        ) {
            const continueGame = confirmDiloag('Continue game in progress?');
            if (continueGame) {
                window.keyPush(GAME_URLS.PLAY_FRIEND);
            }
        }
    }

    render() {
        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    return {
        gameWs: getGameWs(state),
        wsToken: getUserWSToken(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        dispatchSendWsAction: (type, data) =>
            dispatch(sendWsAction(type, data)),
        dispatchReceiveWs: (data) => dispatch(receiveWsAction(data)),
        dispatchRedirect: (url) => dispatch(redirect(url)),
        dispatchClearQueue: () => dispatch(wsClearQueueList()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WSWrapper);
