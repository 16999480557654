import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { logout } from 'store/user/actions';

import { BREAKPOINT } from 'shared/breakpoints';

import { ReactComponent as Back } from 'assets/icons/arrow_back.svg';
import { ReactComponent as Logo } from 'assets/icons/ksc_logo.svg';

import styles from './Header.module.scss';

const Header = ({ show, pathname, ...props }) => {
    const dispatch = useDispatch();

    let logo;

    switch (pathname) {
        case '/':
        case '/onboarding':
            logo = (
                <Link to="/">
                    <Logo />
                </Link>
            );
            break;

        default:
            logo = BREAKPOINT.TABLET ? (
                <Link to="/">
                    <Logo />
                </Link>
            ) : (
                <Link to="/">
                    <Back />
                </Link>
            );
            break;
    }
    return (
        <div className={show ? styles.header : styles.header_bg}>
            {logo}
            {show &&
                (localStorage._k_sqr_at ? (
                    <div onClick={() => dispatch(logout(props))}>
                        <FormattedMessage id="header.sign_out" />
                    </div>
                ) : (
                    <Link
                        to={{
                            pathname: '/login',
                            state: { prevPath: props.location.pathname },
                        }}
                    >
                        <FormattedMessage id="header.sign_in" />
                    </Link>
                ))}
        </div>
    );
};

Header.propTypes = {
    show: PropTypes.bool,
    pathname: PropTypes.string,
};

export default withRouter(Header);
