export const SET_TIME_CONTROL = 'GAME:SET_TIME_CONTROL';
export const SET_VARIANT_TYPE = 'GAME:SET_VARIANT_TYPE';
export const SET_DIFFICULTY_LEVEL = 'GAME:SET_DIFFICULTY_LEVEL';
export const SET_PIECES_COLOR = 'GAME:SET_PIECES_COLOR';
export const SET_PREDEFINED_FEN = 'GAME:SET_PREDEFINED_FEN';

export const PLAYER_COLOR = {
    WHITE: 'white',
    BLACK: 'black',
};

export const PLAYER_COLOR_SHORT = {
    WHITE: 'w',
    BLACK: 'b'
};

export const PLAYER_COLOR_SHORT_MAPPER = {
    w: 'white',
    b: 'black'
};

export const ENGINE_LEVEL = {
    LEVEL1: 0,
    LEVEL2: 3,
    LEVEL3: 5,
    LEVEL4: 10,
    LEVEL5: 20,
};

export const GAME = {
    STANDARD: 'standard',
    CHESS_960: 'chess960',
};

export const GAME_TYPE_LIST = {
    'BULLET_1_0': 'BULLET_1_0',
    'BLITZ_5_0': 'BLITZ_5_0',
    'RAPID_15_5': 'RAPID_15_5',
    'RAPID_30_30': 'RAPID_30_30',
};

export const GAME_TYPE = {
    [GAME_TYPE_LIST.BULLET_1_0]: {
        name: GAME_TYPE_LIST.BULLET_1_0,
        minutes: 1,
        seconds: 0,
        increment: 0
    },
    [GAME_TYPE_LIST.BLITZ_5_0]: {
        name: GAME_TYPE_LIST.BLITZ_5_0,
        minutes: 5,
        seconds: 0,
        increment: 0
    },
    [GAME_TYPE_LIST.RAPID_15_5]: {
        name: GAME_TYPE_LIST.RAPID_15_5,
        minutes: 15,
        seconds: 0,
        increment: 5
    },
    [GAME_TYPE_LIST.RAPID_30_30]: {
        name: GAME_TYPE_LIST.RAPID_30_30,
        minutes: 30,
        seconds: 0,
        increment: 30
    }
};
