import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTableHeight } from 'store/util/actions';

const ScreenSizeWrapper = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const tableHeight =
            window.innerHeight > window.innerWidth
                ? window.innerWidth - 70
                : window.innerHeight - 170;
        dispatch(setTableHeight(tableHeight));
    }, []);
    return <>{props.children}</>;
};

export default ScreenSizeWrapper;
