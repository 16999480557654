import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'store/root/reducer';
import rootSaga from 'store/root/saga';

// Redux dev tools for Chrome
let composeEnhancers = null;

if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
    }) || compose;
} else {
    composeEnhancers = compose;
}
const sagaMiddleware = createSagaMiddleware();
export const runSaga = sagaMiddleware.run;

const configureStore = (initialState) => {
    // Create redux store
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );
    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore();
