export const facebookSDK = (lang) => {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId: process.env.REACT_APP_FB_APP_ID,
            cookie: true,
            xfbml: true,
            autoLogAppEvents: true,
            version: 'v5.0',
        });

        window.FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/${lang}/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
};

export function googleSDK(setAuth2) {
    window.googleSDKLoaded = () => {
        window.gapi.load('auth2', () => {
            const auth2 = window.gapi.auth2.init({
                client_id: process.env.REACT_APP_GOOGLE_APP_ID,
                cookiepolicy: 'single_host_origin',
                scope: 'profile email',
            });
            setAuth2(auth2);
        });
    };

    (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src =
            'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
}