// eslint-disable-next-line
import { takeLatest, put as dispatch } from 'redux-saga/effects';

// import * as actions from './actions';
// import * as api from './api';
import * as constants from './constants';

export function* utilSaga() {
    yield takeLatest(constants.REDIRECT, redirect);
}

export function* redirect({ url }) {
    yield window.keyPush(url);
}
