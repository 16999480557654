import { updateObject } from 'shared/utility';
import {
    setRefreshTokenLS,
    setAccessTokenLS,
    setWsTokenLS,
    setUserLS,
    removeUserLS,
    setChatAuthTokenLS,
} from 'shared/localStorage';
import * as constants from './constants';
import {setAccessToken as APISetAccessToken} from 'API';

const initialState = {
    token: null,
    user: null,
    error: null,
    guestUser: false,
    loginRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    checkUsernameRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    registerRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    startPasswordRecoveryRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    recoverPasswordRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    wsToken: null,
    accessToken: null,
    refreshToken: null,
    chatToken: null,
};

const userLogout = (state) => {
    removeUserLS();
    return updateObject(state, {
        token: null,
        user: null,
        guestUser: false,
    });
};

const authFail = (state, { error }) => {
    return updateObject(state, {
        token: null,
        user: null,
        error,
    });
};

const setWsToken = (state, { token }) => {
    setWsTokenLS(token);
    return updateObject(state, {
        wsToken: token,
    });
};

const setAccessToken = (state, { token }) => {
    setAccessTokenLS(token);
    APISetAccessToken(token);
    return updateObject(state, {
        accessToken: token,
    });
};

const setRefreshToken = (state, { token }) => {
    setRefreshTokenLS(token);
    return updateObject(state, {
        refreshToken: token,
    });
};

const getUserSuccess = (state, { user }) => {
    return updateObject(state, {
        user,
        guestUser: false,
    });
};

const setGuestUser = (state) => {
    return updateObject(state, {
        guestUser: true,
    });
};

const resetUser = (state) => {
    return updateObject(state, {
        guestUser: false,
    });
};

const loginRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error,
    errorType
) => {
    return updateObject(state, {
        user: data,
        loginRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
            errorType,
        },
    });
};

const registerRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        registerRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const checkUsernameRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        checkUsernameRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const startPasswordRecoveryRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error
) => {
    return updateObject(state, {
        startPasswordRecoveryRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};
const recoverPasswordRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error
) => {
    return updateObject(state, {
        recoverPasswordRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const setLocalStorageLogin = (data) => {
    const {
        id,
        username,
        firstname,
        lastname,
        skill_level,
        country,
        city,
        state,
        pro_category,
        premium,
        urs_ranking,
        language,
        timezone,
        avatar,
        about_me,
        status,
        comet_chat_id,
    } = data;

    const user = {
        id,
        username,
        firstname,
        lastname,
        skill_level,
        country,
        city,
        state,
        pro_category,
        premium,
        urs_ranking,
        language,
        timezone,
        avatar,
        about_me,
        status,
        comet_chat_id,
    };

    console.log(user);

    setUserLS(JSON.stringify(user));
};

const registrationCleanup = () => {
    localStorage.removeItem('_k_sqr_go_dta');
    localStorage.removeItem('_k_sqr_go_at');
    localStorage.removeItem('_k_sqr_fb_dta');
    localStorage.removeItem('_k_sqr_fb_at');
};
const emailRegistrationCleanup = () => {
    sessionStorage.removeItem('_k_sqr_m_dta');
};

const setChatToken = (state, { token }) => {
    setChatAuthTokenLS(token);
    return updateObject(state, {
        chatToken: token,
    });
};

// TODO Bojan refresh token
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.USER_LOGOUT:
            return userLogout(state);
        case constants.SET_WS_TOKEN:
            return setWsToken(state, action);
        case constants.SET_ACCESS_TOKEN:
            return setAccessToken(state, action);
        case constants.SET_REFRESH_TOKEN:
            return setRefreshToken(state, action);
        case constants.GET_USER_FAILURE:
            return authFail(state, action);
        case constants.GET_USER_SUCCESS:
            return getUserSuccess(state, action);
        case constants.SET_GUEST_USER:
            return setGuestUser(state);
        case constants.RESET_USER:
            return resetUser(state);
        case constants.LOGIN_REQUEST:
        case constants.FACEBOOK_LOGIN_REQUEST:
        case constants.GOOGLE_LOGIN_REQUEST:
            return loginRequest(state, true, null, null, '');
        case constants.LOGIN_SUCCESS:
        case constants.FACEBOOK_LOGIN_SUCCESS:
        case constants.GOOGLE_LOGIN_SUCCESS:
            setLocalStorageLogin(action.data);
            registrationCleanup();
            return loginRequest(state, false, true, action.data, null);
        case constants.LOGIN_FAILURE:
        case constants.FACEBOOK_LOGIN_FAILURE:
        case constants.GOOGLE_LOGIN_FAILURE:
            return loginRequest(
                state,
                false,
                false,
                null,
                action.error,
                action.errorType
            );
        case constants.REGISTER_REQUEST:
        case constants.EMAIL_REGISTER_REQUEST:
            return registerRequest(state, true, null, null, '');
        case constants.REGISTER_SUCCESS:
            return registerRequest(state, false, true, action.data, null);
        case constants.EMAIL_REGISTER_SUCCESS:
            emailRegistrationCleanup();
            return registerRequest(state, false, true, action.data, null);
        case constants.REGISTER_FAILURE:
        case constants.EMAIL_REGISTER_FAILURE:
            return registerRequest(state, false, false, null, action.error);
        case constants.CHECK_USERNAME_REQUEST:
            return checkUsernameRequest(state, true, null, null, null);
        case constants.CHECK_USERNAME_SUCCESS:
            return checkUsernameRequest(state, false, true, action.data, null);
        case constants.CHECK_USERNAME_FAILURE:
            return checkUsernameRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        case constants.START_PASSWORD_RECOVERY_REQUEST:
            return startPasswordRecoveryRequest(state, true, null, null, null);
        case constants.START_PASSWORD_RECOVERY_SUCCESS:
            return startPasswordRecoveryRequest(
                state,
                false,
                true,
                action.data,
                null
            );
        case constants.START_PASSWORD_RECOVERY_FAILURE:
            return startPasswordRecoveryRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        case constants.RECOVER_PASSWORD_REQUEST:
            return recoverPasswordRequest(state, true, null, null, null);
        case constants.RECOVER_PASSWORD_SUCCESS:
            return recoverPasswordRequest(
                state,
                false,
                true,
                action.data,
                null
            );
        case constants.RECOVER_PASSWORD_FAILURE:
            return recoverPasswordRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        case constants.GET_CHAT_AUTH_TOKEN_SUCCESS:
            return setChatToken(state, action);
        default:
            return state;
    }
};

export default reducer;
