import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Spinner from 'UI/Spinner/Spinner';
import Splash from '../Splash/Splash';

import { getAccessTokenLS } from 'shared/localStorage';

import {
    GAME_URLS,
    MAIN_URLS,
    ONBOARDING_URLS,
    SPECTATING_URLS,
} from 'shared/urlList';

// Lazy imports of components
const Login = lazy(() => import('components/Login/Login'));
const SocialRegistration = lazy(() =>
    import('components/SocialRegistration/SocialRegistration')
);
const EmailRegistration = lazy(() =>
    import('components/EmailRegistration/EmailRegistration')
);
const EmailRegistration2 = lazy(() =>
    import('components/EmailRegistration2/EmailRegistration2')
);
const Privacy = lazy(() => import('components/Privacy/Privacy'));
const Terms = lazy(() => import('components/Terms/Terms'));
const Onboarding = lazy(() => import('components/Onboarding/Onboarding'));
const PasswordRecovery = lazy(() =>
    import('components/PasswordRecovery/PasswordRecovery')
);
const Home = lazy(() => import('components/Home/Home'));
const ComputerGame = lazy(() => import('components/Game/GameVsComputer'));
const OpponentGame = lazy(() => import('components/Game/GameVsOpponent'));
const FriendGame = lazy(() => import('components/Game/GameVsFriend'));
const NewGame = lazy(() => import('components/NewGame/NewGame'));
const GameSettings = lazy(() => import('components/GameSettings/GameSettings'));
const Playhub = lazy(() => import('components/Playhub/Playhub'));

const OTBTournamentList = lazy(() =>
    import('components/OTBTournaments/OTBTournaments')
);
const OTBGameList = lazy(() => import('components/OTBTournaments/GameList'));
const SpectateOTBGame = lazy(() => import('components/Game/WatchOTBGame'));

const RouterWrapper = (props) => {
    const accessToken = getAccessTokenLS();

    return (
        <Suspense
            fallback={
                <div className="spinner_center">
                    <Spinner />
                </div>
            }
        >
            {accessToken ? <LogedIn /> : <Guest props={props} />}
        </Suspense>
    );
};

const LogedIn = () => {
    return (
        <Switch>
            <Route exact path={MAIN_URLS.LANDING_PAGE} component={Splash} />
            <Route exact path={MAIN_URLS.HOME} component={Home} />
            <Route
                exact
                path={ONBOARDING_URLS.RESET_PASSWORD}
                component={PasswordRecovery}
            />
            <Route
                path={GAME_URLS.SETTINGS_WILDCARD}
                component={GameSettings}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_COMPUTER}
                component={ComputerGame}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_OPPONENT}
                component={OpponentGame}
            />
            <Route exact path={GAME_URLS.PLAY_FRIEND} component={FriendGame} />
            <Route
                exact
                path={SPECTATING_URLS.WATCH}
                component={OTBTournamentList}
            />
            <Route
                exact
                path={SPECTATING_URLS.TOURNAMENT_GAME_LIST}
                component={OTBGameList}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE_OTB_GAME}
                component={SpectateOTBGame}
            />
            <Route exact path={MAIN_URLS.TERMS} component={Terms} />
            <Route exact path={GAME_URLS.NEW_GAME} component={NewGame} />
            <Route exact path={MAIN_URLS.PRIVACY} component={Privacy} />
            <Route exact path={MAIN_URLS.PLAYHUB} component={Playhub} />
            <Redirect to={GAME_URLS.NEW_GAME} />
        </Switch>
    );
};

const Guest = ({ props }) => {
    return (
        <Switch>
            <Route exact path="/" component={Splash} />
            <Route
                exact
                path={ONBOARDING_URLS.ONBOARDING}
                render={() => <Onboarding auth2={props.auth2} />}
            />
            <Route
                path={GAME_URLS.SETTINGS_WILDCARD}
                component={GameSettings}
            />
            <Route
                exact
                path={GAME_URLS.PLAY_COMPUTER}
                component={ComputerGame}
            />
            <Route exact path={MAIN_URLS.TERMS} component={Terms} />
            <Route exact path={GAME_URLS.NEW_GAME} component={NewGame} />
            <Route exact path={MAIN_URLS.PRIVACY} component={Privacy} />
            <Route exact path={ONBOARDING_URLS.LOGIN} component={Login} />
            <Route
                exact
                path={ONBOARDING_URLS.REGIESTER}
                component={EmailRegistration}
            />
            <Route
                exact
                path={ONBOARDING_URLS.VERIFY_EMAIL}
                component={EmailRegistration2}
            />
            <Route
                exact
                path={ONBOARDING_URLS.REGISTER_SOCIAL}
                component={SocialRegistration}
            />
            <Route
                exact
                path={ONBOARDING_URLS.RESET_PASSWORD}
                component={PasswordRecovery}
            />
            <Route
                exact
                path={SPECTATING_URLS.WATCH}
                component={OTBTournamentList}
            />
            <Route
                exact
                path={SPECTATING_URLS.TOURNAMENT_GAME_LIST}
                component={OTBGameList}
            />
            <Route
                exact
                path={SPECTATING_URLS.SPECTATE_OTB_GAME}
                component={SpectateOTBGame}
            />
            <Redirect to={MAIN_URLS.LANDING_PAGE} />
        </Switch>
    );
};

export default RouterWrapper;
