import { SET_TABLE_HEIGHT } from './constants';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TABLE_HEIGHT:
            return {...state, tableHeight: action.height};
        default:
            return state;
    }
};

export default reducer;
