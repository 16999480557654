export default {
    en: {
        'util.required_data': 'Required data',
        'util.valid_email': 'Invalid email address.',
        'util.valid_name': 'Please enter a valid name',
        'util.valid_password':
            'Password must contain between { minLength } and { maxLength } characters, and at least one digit or one special character (~!@#$%^&*)',
        'util.min_length': 'Field requires { minLength } characters min',
        'util.max_length': 'Field requires { maxLength } characters max',
        'util.mp3_format_only': '.mp3 format only',
        'util.data_size_limit': 'Data size limit is 10MB',
        'util.special_not_accepted': 'Special characters are not accepted.',
    },
};
