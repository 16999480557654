import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { PropTypes } from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import * as actions from 'store/user/actions';

import { facebookSDK, googleSDK } from './social';

import Layout from 'containers/Layout/Layout';
import WSWrapper from 'components/Wrappers/WSWrapper';
import TokenWrapper from 'components/Wrappers/TokenWrapper';
import RouterWrapper from 'components/Wrappers/RouterWrapper';
import ScreenSizeWrapper from 'components/Wrappers/ScreenSizeWrapper';

import messages from 'assets/translations/messages';

const App = ({ store, lang, checkAuth, ...props }) => {
    window.keyPush = props.history.push;
    const [auth2, setAuth2] = useState(null);
    const chatAppSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(process.env.REACT_APP_COMETCHAT_API_REGION)
        .build();

    useEffect(() => {
        googleSDK(setAuth2);
        facebookSDK(lang);

        // CometChat initialization
        CometChat.init(
            process.env.REACT_APP_COMETCHAT_APP_ID,
            chatAppSetting
        ).then(
            () => {
                // eslint-disable-next-line
                console.log('Chat initialization completed successfully');
                // You can now call login function.
            },
            (error) => {
                // eslint-disable-next-line
                console.log('Chat initialization failed with error:', error);
                // Check the reason for error and take appropriate action.
            }
        );
    }, [lang]);

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            <Provider store={store}>
                <TokenWrapper>
                    <WSWrapper>
                        <Layout>
                            <ScreenSizeWrapper>
                                <RouterWrapper auth2={auth2} />
                            </ScreenSizeWrapper>
                        </Layout>
                    </WSWrapper>
                </TokenWrapper>
            </Provider>
        </IntlProvider>
    );
};

App.propTypes = {
    guest: PropTypes.bool,
    lang: PropTypes.string.isRequired,
    checkAuth: PropTypes.func,
    logout: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        guest: state.user.guestUser,
        lang: state.locale.lang,
    };
};

const mapDispatchToProps = {
    checkAuth: (props) => actions.checkAuth(props),
    logout: (props) => actions.logout(props),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
