export default {
    en: {
        'error.user_doesnt_exist': "User doesn't exist",
        'error.user_cant_login': "User can't login",
        'error.user_username_or_password_missing':
            'Username or password missing',
        'error.user_username_exists': 'This username is already taken.',
        'error.User deactivated': 'User deactivated',
        'error.user_wrong_password': 'Incorrect password.',
        "error.Passwords don't match": 'Passwords do not match.',
        'error.Invalid token': 'Invalid token',
        'error.undefined': 'Something went wrong',
        'error.Forbidden': 'Forbidden',
        'error.user_email_exists': 'That email address is already taken.',
        'error.user_email_required': 'User email required',
        'error.user_password_required': 'User password required',
        'error.user_invalid_auth_code': 'Invalid auth code',
        'error.user_invalid_token': 'Invalid token',
        'error.user_refresh_token_expired': 'Refresh token expired',
        'error.user_passwords_dont_match': 'Passwords do not match.',
        'error.password_recovery_invalid_token':
            'Password recovery invalid token',
        'error.email_verification_invalid_token':
            'Email verification invalid token',
        'error.server_error': 'Server error',
        'error.user_username_profane': 'Keep it clean, please.',
    },
};
