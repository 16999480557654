import { END, eventChannel } from 'redux-saga';
import {
    call,
    take,
    put as dispatch,
    cancelled,
    takeEvery,
} from 'redux-saga/effects';

export function createWebSocketConnection(data) {
    if (!data.token) {
        return Promise.resolve(false);
    }

    return new Promise((resolve, reject) => {
        const socket = new WebSocket(`${data.endpoint}?token=${data.token}`);
        socket.onopen = function() {
            resolve(socket);
        };

        socket.onerror = function(evt) {
            reject(evt);
        };
    });
}

export function* listenForSocketMessages(socket, params) {
    // let socket;
    let socketChannel;
    try {
        socketChannel = yield call(createSocketChannel, socket);

        // tell the application that we have a connection
        yield dispatch(params.connectionSuccess(socketChannel));

        while (true) {
            // wait for a message from the channel
            const payload = yield take(socketChannel);

            // a message has been received, dispatch an action with the message payload
            yield dispatch(handleNewWsMessage(payload, params.dispatchAction));
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('There was an error', error);
    } finally {
        // Handle intentionally closed/cancelled
        if (yield cancelled()) {
            // close the channel
            socketChannel.close();

            // close the WebSocket connection
            socket.close();

            // eslint-disable-next-line no-console
            console.log('cancelled');
        } else {
            // eslint-disable-next-line no-console
            console.error('WebSocket disconnected!');

            yield dispatch(params.dispatchUnexpectedDisconnect());
        }
    }
}

export function* listenForMessagesForSocket(socket, params) {
    if (socket.readyState && socket.readyState !== WebSocket.CLOSED) {
        yield takeEvery(params.action, handleSentAction, socket);
        return Promise.resolve(true);
    } else {
        // eslint-disable-next-line no-console
        console.log('Websocket is closed');
    }
}

function createSocketChannel(socket) {
    return eventChannel((emit) => {
        const unsubscribe = () => {
            socket.onmessage = null;
        };

        socket.onmessage = (event) => {
            emit(event.data);
        };

        socket.onclose = () => {
            emit(END);
        };

        return unsubscribe;
    });
}

function handleSentAction(socket, data) {
    const wsData = { ...data };
    wsData.type = wsData.wsAction;
    delete wsData.wsAction;

    if (socket.readyState && socket.readyState !== WebSocket.CLOSED) {
        // eslint-disable-next-line no-console
        console.log('handleSentAction wsData', wsData);
        socket.send(JSON.stringify(wsData));
    }
}

function handleNewWsMessage(data, dispatchAction) {
    const parsedData = JSON.parse(data);
    // eslint-disable-next-line no-console
    console.log('dispatchAction parsedData', parsedData);
    return dispatchAction(parsedData);
}
