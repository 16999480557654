import React from 'react';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function confirmWrapper({
    title,
    message,
    buttons,
    onClickOutside,
    onKeyPressEscape,
}) {
    confirmAlert({
        customUI: ({ onClose }) => {
            const isMessageArray = Array.isArray(message) && message.length > 1;
            return (
                <div className="react-confirm-alert">
                    <div className="react-confirm-alert-body">
                        <h2>{title}</h2>
                        {!isMessageArray && <p>{message}</p>}
                        {isMessageArray &&
                            message.map((text, index) => {
                                return <p key={index}>{text}</p>;
                            })}
                        <div className="react-confirm-alert-button-group">
                            {buttons.map((button, index) => {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            onClose();
                                            button.onClick();
                                        }}
                                    >
                                        {button.label}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        },
        onClickOutside: () => onClickOutside(),
        onKeypressEscape: () => onKeyPressEscape(),
    });
}

export function confirmDiloag(text) {
    // eslint-disable-next-line no-alert
    return window.confirm(text);
}

export function drawOffer(cbFunctions) {
    confirmWrapper({
        title: 'Do you want draw?',
        message: '',
        buttons: [
            {
                label: 'Yes',
                onClick: () => cbFunctions.yesCb(),
            },
            {
                label: 'No',
                onClick: () => cbFunctions.noCb(),
            },
        ],
        onClickOutside: () => cbFunctions.noCb(),
        onKeyPressEscape: () => cbFunctions.noCb(),
    });
}

export function gameChallenge(message, cbFunctions) {
    confirmWrapper({
        title: 'Friend challenge',
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cbFunctions.yesCb(),
            },
            {
                label: 'No',
                onClick: () => cbFunctions.noCb(),
            },
        ],
        onClickOutside: () => cbFunctions.noCb(),
        onKeyPressEscape: () => cbFunctions.noCb(),
    });
}

export function acceptCall(caller, type, cbFunctions) {
    confirmWrapper({
        title: `Incoming ${type} call from ${caller}`,
        message: 'Do you accept?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => cbFunctions.yesCb(),
            },
            {
                label: 'No',
                onClick: () => cbFunctions.noCb(),
            },
        ],
        onClickOutside: () => cbFunctions.noCb(),
        onKeyPressEscape: () => cbFunctions.noCb(),
    });
}

export function gameChallengeTimeout(message, cbFunctions) {
    confirmWrapper({
        title: 'Friend challenge',
        message,
        buttons: [
            {
                label: 'Close',
                onClick: () => cbFunctions.onClose(),
            },
        ],
        willUnmount: () => cbFunctions.onClose(),
        onClickOutside: () => cbFunctions.onClose(),
        onKeyPressEscape: () => cbFunctions.onClose(),
    });
}

export function gameEndMessage(title, message, buttons) {
    confirmWrapper({
        title,
        message,
        buttons,
        onClickOutside: () => {},
        onKeyPressEscape: () => {},
    });

    // customUI: ({ onClose }) => {
    //     return (
    //         <div className="custom-ui">
    //             <h1>
    //                 You{' '}
    //                 {
    //                     pointsToEndResult[
    //                         this.props.result.playerPoints
    //                     ]
    //                 }
    //                 !
    //             </h1>
    //             <p>
    //                 Game ended by{' '}
    //                 {reasonConverter(this.props.result.reason)}!
    //             </p>
    //             {bestMoveString && <p>bestMoveString}!</p>}
    //             <button
    //                 onClick={() =>
    //                     this.props.actions.goToNewGameScreen()
    //                 }
    //             >
    //                 Close
    //             </button>
    //             <button
    //                 onClick={() =>
    //                     this.props.actions.searchForOpponents()
    //                 }
    //             >
    //                 New game
    //             </button>
    //         </div>
    //     );
    // },
}
