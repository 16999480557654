export const CHECK_AUTH = 'USER:CHECK_AUTH';
export const CHECK_AUTH_LOGOUT = 'USER:CHECK_AUTH_LOGOUT';
export const USER_LOGOUT = 'USER:USER_LOGOUT';

export const SET_WS_TOKEN = 'USER:SET_WS_TOKEN';

export const GET_USER_REQUEST = 'USER:GET_USER_REQUEST';
export const GET_USER_FAILURE = 'USER:GET_USER_FAILURE';
export const GET_USER_SUCCESS = 'USER:GET_USER_SUCCESS';

export const GET_REFRESH_TOKEN_REQUEST = 'USER:GET_REFRESH_TOKEN_REQUEST';
export const GET_REFRESH_TOKEN_SUCCESS = 'USER:GET_REFRESH_TOKEN_SUCCESS';
export const GET_REFRESH_TOKEN_FAILURE = 'USER:GET_REFRESH_TOKEN_FAILURE';

export const GET_CHAT_AUTH_TOKEN_REQUEST = 'USER:GET_CHAT_AUTH_TOKEN_REQUEST';
export const GET_CHAT_AUTH_TOKEN_SUCCESS = 'USER:GET_CHAT_AUTH_TOKEN_SUCCESS';
export const GET_CHAT_AUTH_TOKEN_FAILURE = 'USER:GET_CHAT_AUTH_TOKEN_FAILURE';

export const SET_REFRESH_TOKEN = 'USER:SET_REFRESH_TOKEN';

export const SET_ACCESS_TOKEN = 'USER:SET_ACCESS_TOKEN';

export const SET_GUEST_USER = 'USER:SET_GUEST_USER';
export const RESET_USER = 'USER:RESET_USER';
export const SET_USER_TYPE = 'USER:SET_USER_TYPE';

export const LOGIN_REQUEST = 'USER:LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USER:LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USER:LOGIN_FAILURE';

export const REGISTER_REQUEST = 'USER:REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'USER:REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'USER:REGISTER_FAILURE';

export const EMAIL_REGISTER_REQUEST = 'USER:EMAIL_REGISTER_REQUEST';
export const EMAIL_REGISTER_SUCCESS = 'USER:EMAIL_REGISTER_SUCCESS';
export const EMAIL_REGISTER_FAILURE = 'USER:EMAIL_REGISTER_FAILURE';

export const FACEBOOK_LOGIN_REQUEST = 'USER:FACEBOOK_LOGIN_REQUEST';
export const FACEBOOK_LOGIN_SUCCESS = 'USER:FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_FAILURE = 'USER:FACEBOOK_LOGIN_FAILURE';

export const GOOGLE_LOGIN_REQUEST = 'USER:GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_SUCCESS = 'USER:GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'USER:GOOGLE_LOGIN_FAILURE';

export const CHECK_USERNAME_REQUEST = 'USER:CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS = 'USER:CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAILURE = 'USER:CHECK_USERNAME_FAILURE';

export const START_PASSWORD_RECOVERY_REQUEST =
    'USER:START_PASSWORD_RECOVERY_REQUEST';
export const START_PASSWORD_RECOVERY_SUCCESS =
    'USER:START_PASSWORD_RECOVERY_SUCCESS';
export const START_PASSWORD_RECOVERY_FAILURE =
    'USER:START_PASSWORD_RECOVERY_FAILURE';

export const RECOVER_PASSWORD_REQUEST = 'USER:RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_SUCCESS = 'USER:RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'USER:RECOVER_PASSWORD_FAILURE';
