import * as constants from './actionConstants';
import { DRAW_OFFER_RESPONSE, RECEIVE_WS, SEND_WS } from './wsConstants';
import { getResultFromServerWinner } from 'shared/game';
import { removeGameIdLS } from 'shared/localStorage';
import { secondsToChessTime } from 'shared/time';

const initialState = {
    connectionInProgress: false,
    connectionAttempts: 0,
    connected: false,
    search: {
        inProgress: false,
        users: [],
    },
    matched: {
        data: null,
        inProgress: false,
    },
    waitingFriend: {
        deny: false,
        timeout: false,
        denyReason: '',
    },
    serverMove: null,
    result: null,
    drawOffer: false,
    challengeList: [],
    sendListQueue: [],
};

const wsSendAction = (state, action) => {
    if (!state.connected) {
        const newQueue = [...state.sendListQueue, action];
        return {
            ...state,
            sendListQueue: newQueue,
        };
    }
    const type = action.wsAction.type ? action.wsAction.type : action.wsAction;
    switch (type) {
        case SEND_WS.CONNECT:
            return {
                ...state,
                matched: {
                    data: null,
                    inProgress: true,
                },
            };
        case SEND_WS.SEARCH_USER:
            return {
                ...state,
                search: {
                    ...state.search,
                    inProgress: true,
                },
            };
        case SEND_WS.DRAW_OFFER_RESPONSE:
            return {
                ...state,
                drawOffer: false,
            };
        case SEND_WS.NEW_GAME:
            return {
                ...state,
                waitingFriend: {
                    deny: false,
                    timeout: false,
                    denyReason: '',
                },
            };
        case SEND_WS.NEW_GAME_INVITE_RESPONSE:
            const isAccepted = action.data.resp === DRAW_OFFER_RESPONSE.ACCEPT;
            const newChallengeList = isAccepted
                ? []
                : state.challengeList.filter(
                      (challenge) => challenge.user.id !== action.data.user
                  );

            return {
                ...state,
                drawOffer: false,
                challengeList: newChallengeList,
            };
        case SEND_WS.CANCEL_WAITING:
            return {
                ...state,
                waitingFriend: {
                    deny: false,
                    timeout: false,
                    denyReason: '',
                },
            };
        case SEND_WS.DISCONNECT_OTB_GAME:
            return {
                ...state,
                spectating: undefined,
                result: undefined,
                serverMove: undefined,
            };
        default:
            return state;
    }
};

const receiveWsAction = (state, action) => {
    switch (action.type) {
        case RECEIVE_WS.USER_SEARCH_RESULT:
            return {
                ...state,
                search: {
                    inProgress: false,
                    users: action.data.users,
                },
            };
        case RECEIVE_WS.MATCHED:
            return {
                ...state,
                matched: {
                    data: action.data,
                    inProgress: false,
                },
            };
        case RECEIVE_WS.MOVE:
            return {
                ...state,
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
            };
        case RECEIVE_WS.GAME_OVER:
            removeGameIdLS();
            return {
                ...state,
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
                result: {
                    score: getResultFromServerWinner(action.data.winner),
                    reason: action.data.end,
                    accuracy: action.data.best_moves,
                },
                matched: {
                    data: null,
                    inProgress: false,
                },
            };
        case RECEIVE_WS.DRAW_OFFER:
            return {
                ...state,
                drawOffer: true,
            };
        case RECEIVE_WS.CONNECT:
            return {
                ...state,
                matched: {
                    data: action.data,
                    inProgress: false,
                    reconnectedFen: action.data.fen,
                    reconnectedClock: action.data.clock,
                },
            };
        case RECEIVE_WS.NEW_GAME_INVITE:
            const newChallenge = {
                ...secondsToChessTime(action.data.time),
                increment: action.data.increment,
                user: action.data.user,
            };
            return {
                ...state,
                challengeList: [...state.challengeList, newChallenge],
                waitingFriend: {
                    timeout: false,
                    deny: false,
                    denyReason: '',
                },
            };
        case RECEIVE_WS.GET_OTB_TOURNAMENT_LIST:
            return {
                ...state,
                tournamentList: action.data,
            };
        case RECEIVE_WS.SPECTATE_OTB_GAME:
            return {
                ...state,
                spectating: [action.data],
            };
        case RECEIVE_WS.OTB_MOVE:
            return {
                ...state,
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
            };
        case RECEIVE_WS.OTB_GAME_OVER:
            return {
                ...state,
                result: {
                    score: getResultFromServerWinner(action.data.winner),
                    reason: action.data.end,
                    accuracy: action.data.best_moves,
                },
                serverMove: {
                    move: action.data.move,
                    clock: action.data.clock,
                },
            };
        case RECEIVE_WS.NEW_GAME_INVITE_RESPONSE:
            if (action.data.resp === 'timeout') {
                return {
                    ...state,
                    waitingFriend: {
                        timeout: true,
                        denyReason: action.data.resp,
                    },
                    challengeList: [],
                };
            }
            return {
                ...state,
                waitingFriend: {
                    deny: true,
                    timeout: false,
                    denyReason: action.data.resp,
                },
                challengeList: [],
            };

        default:
            return state;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.WS_SEND_ACTION:
            return wsSendAction(state, action);
        case constants.WS_RECEIVE_ACTION:
            return receiveWsAction(state, action.data);

        case constants.WS_CONNECTION_REQUEST:
            return {
                ...state,
                connectionInProgress: true,
                connectionAttempts: state.connectionAttempts + 1,
            };
        case constants.WS_CONNECTION_FAILURE:
            return {
                ...state,
                connected: false,
                connectionInProgress: false,
                error: action.error,
                disconnected: true,
            };
        case constants.WS_CONNECTION_SUCCESS:
            return {
                ...state,
                connected: true,
                connectionInProgress: false,
                disconnected: false,
                connectionAttempts: 0,
            };
        case constants.WS_DISCONNECT_SUCCESS:
            return { ...state, connected: false };
        case constants.WS_UNEXPECTED_DISCONNECT:
            return { ...state, connected: false, disconnected: true };

        case constants.WS_CLEAR_QUEUE:
            const newQueue = [...state.sendListQueue];
            delete newQueue[action.index];
            return { ...state, sendListQueue: newQueue };
        default:
            return state;
    }
};

export default reducer;
