import { getAccessTokenLS, getWsTokenLS, getUserLS } from 'shared/localStorage';

const getUserBaseReducer = (state) => state.user;
export const getLoginRequest = (state) => state.user.loginRequest;
export const getUser = (state) => {
    if (state.user.user) {
        return state.user.user;
    }
    const lsUser = getUserLS();
    if (lsUser) {
        return JSON.parse(lsUser);
    }

    return {};
};
export const getRegisterRequest = (state) => state.user.registerRequest;
export const getUsernameCheckRequest = (state) =>
    state.user.checkUsernameRequest;
export const startPasswordRecoveryRequest = (state) =>
    state.user.startPasswordRecoveryRequest;
export const recoverPasswordRequest = (state) =>
    state.user.recoverPasswordRequest;

export const getUserAccessToken = (state) => {
    if (!state) {
        return getAccessTokenLS();
    }
    const token = getUser(state).accessToken;
    return token ? token : getAccessTokenLS();
};

export const getUserWSToken = (state) => {
    if (!state) {
        return getWsTokenLS();
    }
    const token = getUserBaseReducer(state).wsToken;
    return token ? token : getWsTokenLS();
};

//
// export const getLoginRequestInProgress = (loginRequestState) => loginRequestState.inProgress;
// export const getLoginRequestStatus     = (loginRequestState) => loginRequestState.isSuccessful;
// export const getLoginRequestData       = (loginRequestState) => loginRequestState.data;
