import { SEND_WS } from './wsConstants';
import { chessTimeToSeconds } from 'shared/time';

export const searchNewGameWs = (data) => {
    return {
        type: SEND_WS.NEW_GAME,
        data: {
            variant: data.gameType,
            time: chessTimeToSeconds(data.time),
            increment: data.time.increment,
        },
    };
};

export const cancelNewGameSearchWs = (data = {}) => {
    return {
        type: SEND_WS.CANCEL_WAITING,
        data,
    };
};

export const sendMoveWs = (id, move) => {
    return {
        type: SEND_WS.MOVE,
        data: {
            id: id,
            move: move,
        },
    };
};

export const sendBestMovesWs = (id, move, ply) => {
    return {
        type: SEND_WS.BEST_MOVES,
        data: {
            id: id,
            move: parseInt(ply) - 1,
            best_moves: [move],
        },
    };
};

export const resignWs = (gameId) => {
    return {
        type: SEND_WS.RESIGN,
        data: {
            id: gameId,
        },
    };
};

export const offerDrawWs = (gameId) => {
    return {
        type: SEND_WS.DRAW_OFFER,
        data: {
            id: gameId,
        },
    };
};

export const offerDrawResponseWs = (gameId, response) => {
    return {
        type: SEND_WS.DRAW_OFFER_RESPONSE,
        data: {
            id: gameId,
            resp: response,
        },
    };
};

export const searchUser = (query) => {
    return {
        type: SEND_WS.SEARCH_USER,
        data: {
            query: query,
        },
    };
};

export const challengeFriend = (data, friendId) => {
    return {
        type: SEND_WS.NEW_GAME,
        data: {
            variant: data.gameType,
            time: chessTimeToSeconds(data.time),
            increment: data.time.increment,
            user: friendId,
            start_fen: data.predefinedFen ? data.predefinedFen : null,
        },
    };
};

export const friendChallengeResponse = (id, response) => {
    return {
        type: SEND_WS.NEW_GAME_INVITE_RESPONSE,
        data: {
            user: id,
            resp: response,
        },
    };
};

export const cancelChallengeFriend = (id) => {
    return {
        type: SEND_WS.CANCEL_WAITING,
        data: {
            user: id,
        },
    };
};

export const reconnect = (id) => {
    return {
        type: SEND_WS.CONNECT,
        data: {
            id: Number.parseInt(id),
        },
    };
};

export const getOTBTournamentList = (data = {}) => {
    return {
        type: SEND_WS.GET_OTB_TOURNAMENT_LIST,
        data,
    };
};

export const spectateOTBGame = (gameId) => {
    return {
        type: SEND_WS.SPECTATE_OTB_GAME,
        data: {
            id: parseInt(gameId)
        },
    };
};

export const disconnectOTBGame = (gameId) => {
    return {
        type: SEND_WS.DISCONNECT_OTB_GAME,
        data: {
            id: parseInt(gameId)
        },
    };
}