export const replaceWildcards = (url, valueArray) => {
    return url.replace(/:[a-zA-Z]+/g, () => valueArray.shift());
};

export const MAIN_URLS = {
    LANDING_PAGE: '/',
    HOME: '/home',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    PLAYHUB: '/playhub',
};

export const GAME_URLS = {
    NEW_GAME: '/new-game',
    PLAY_COMPUTER: '/game/computer',
    PLAY_OPPONENT: '/game/opponent',
    PLAY_FRIEND: '/game/friend',
    SETTINGS_WILDCARD: '/game-preferences/:type',
    SETTINGS_COMPUTER: '/game-preferences/computer',
    SETTINGS_OPPONENT: '/game-preferences/opponent',
    SETTINGS_FRIEND: '/game-preferences/friend',
};

export const ONBOARDING_URLS = {
    LOGIN: '/login',
    ONBOARDING: '/onboarding',
    REGIESTER: '/register',
    VERIFY_EMAIL: '/verify_email',
    REGISTER_SOCIAL: '/register/:social',
    RESET_PASSWORD: '/reset_password',
};

export const SPECTATING_URLS = {
    WATCH: '/watch',
    TOURNAMENT_GAME_LIST: '/game-list/:tournamentId/:categoryId/:roundId',
    SPECTATE_OTB_GAME: '/spectate/otb/:id',
};
