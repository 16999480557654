const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: '_k_sqr_at',
    WS_TOKEN: '_k_sqr_ws_t',
    REFRESH_TOKEN: '_k_sqr_rt',
    LOGGED_USER_INFO: 'user',
    GAME_ID: 'gameId',
    GAME_TYPE_CHOICE: 'gameTypeChoice',
    GAME_SETTINGS: 'gameSettings',
    CHAT_AUTH_TOKEN: '_k_sqr_cu',
};

// Tokens
export const setAccessTokenLS = (token) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
};

export const getAccessTokenLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
};

export const setRefreshTokenLS = (token) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token);
};

export const getRefreshTokenLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
};

export const setWsTokenLS = (token) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.WS_TOKEN, token);
};

export const getWsTokenLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.WS_TOKEN);
};

// User info
export const setUserLS = (user) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.LOGGED_USER_INFO, user);
};

export const getUserLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_USER_INFO);
};

export const removeUserLS = () => {
    return localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGGED_USER_INFO);
};

// Game id
export const setGameIdLS = (gameId) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.GAME_ID, gameId);
};

export const getGameIdLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.GAME_ID);
};

export const removeGameIdLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.GAME_ID);
};

// Game type choice
export const saveGameTypeChoiceLS = (choice) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.GAME_TYPE_CHOICE, choice);
};

export const getGameTypeChoiceLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.GAME_TYPE_CHOICE);
};

// Game settings
export const saveGameSettingsLS = (data) => {
    localStorage.setItem(
        LOCAL_STORAGE_KEYS.GAME_SETTINGS,
        JSON.stringify(data)
    );
};

export const getGameSettingsLS = () => {
    const settings = localStorage.getItem(LOCAL_STORAGE_KEYS.GAME_SETTINGS);
    return settings ? JSON.parse(settings) : null;
};

// Chat User UID
export const setChatAuthTokenLS = (authToken) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CHAT_AUTH_TOKEN, authToken);
};

export const getChatAuthTokenLS = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CHAT_AUTH_TOKEN);
};

export const removesetChatAuthTokenLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CHAT_AUTH_TOKEN);
};
