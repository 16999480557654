import HOME from './sections/home';
import ERROR from './sections/error';
import UTIL from './sections/util';

export default {
    en: {
        ...ERROR.en,
        ...UTIL.en,

        // Input -------------------------------------------
        'input.enter_email': 'Enter your email',
        'input.enter_pass': 'Enter your password',
        'input.enter_new_pass': 'Enter new password',
        'input.repeat_new_pass': 'Re-enter new password',
        'input.firstname': 'First name',
        'input.lastname': 'Last name',
        // ------------------------------------------------

        // Header ----------------------------
        'header.sign_in': 'Sign in',
        'header.sign_out': 'Sign out',
        // ---------------------------------

        // Onboarding ----------------------------
        'onboarding.title': 'Welcome!',
        'onboarding.subtitle': 'Please select an option.',
        'onboarding.continue_facebook': 'Continue with Facebook',
        'onboarding.continue_google': 'Continue with Google',
        'onboarding.continue_email': 'Continue with Email',
        'onboarding.terms_and_conditions':
            'By signing up, I agree to Keysquare’s <link_1>Terms of Service, Payments Terms of Service</link_1>, and <link_2>Privacy Policy</link_2>.',
        // --------------------------------------

        // Login -----------------------------------------
        'login.welcome': 'Welcome!',
        'login.subtitle': 'Sign in to continue.',
        'login.sign_in_button': 'Sign in',
        'login.forgot_password': 'Forgot password?',
        'login.no_account': "Don't have an account yet?",
        'login.sign_up': 'Sign up',
        // ------------------------------------------------

        // EmailRegistartion -----------------------------------------------------------------------------------------------------------
        'email_registartion.sign_up': 'Sign up',
        'email_registartion.next_button': 'Next',
        'email_registartion.check_email': 'Check your email',
        'email_registartion.check_email_text':
            'A confirmation email has been sent to { userMail }. Please click the link in the email and you’ll be brought back here.',
        // ----------------------------------------------------------------------------------------------------------------------------

        // EmailRegistartion2 -----------------------------------------------------------------------------------------------------------
        'email_registartion_2.welcome': 'Welcome!',
        'email_registartion_2.enter_name': 'Please enter your name.',
        'email_registartion_2.next_button': 'Next',
        'email_registartion_2.go_button': 'Let’s go!',
        'email_registartion_2.username_title': 'And, your username?',
        'email_registartion_2.username_subtitle':
            'This is what’s visible to other Keysquare users.',
        'email_registartion_2.birthdate_title': 'When is your birthday?',
        'email_registartion_2.birthdate_subtitle':
            'You must be at least 12 years old to use Keysquare Chess. Don’t worry — we won’t share your age with anyone.',
        'email_registartion_2.skill_level_title': 'Just one more thing.',
        'email_registartion_2.skill_level_subtitle':
            'How would you describe your skill level in chess?',
        // ----------------------------------------------------------------------------------------------------------------------------

        // SocialRegistration -----------------------------------------------------------------------------------------------------------
        'social_registration.next_button': 'Next',
        'social_registration.go_button': 'Let’s go!',
        'social_registration.birthadate_title': 'Welcome, { firstname }!',
        'social_registration.birthadate_subtitle': 'When is your birthday?',
        'social_registration.birthadate_paragraph':
            'You must be at least 12 years old to use Keysquare Chess. Don’t worry — we won’t share your age with anyone.',
        'social_registration.username_title': 'And, your username?',
        'social_registration.username_subtitle':
            'This is what’s visible to other Keysquare users.',
        'social_registration.skill_level_title': 'Just one more thing.',
        'social_registration.skill_level_subtitle':
            'How would you describe your skill level in chess?',
        // ----------------------------------------------------------------------------------------------------------------------------

        // SignUp -----------------------------------------
        'sign_up.sign_up': 'Sign up',
        'sign_up.sign_up_button': 'Sign up',
        'sign_up.forgot_password': 'Forgot password?',
        // ------------------------------------------------

        // PasswordRecovery --------------------------------------------------------------------------------------------
        'password_recovery.step_1_title': 'Reset password',
        'password_recovery.step_1_subtitle':
            'Enter the email address you used to sign up and we’ll send you a verification link to reset your password.',
        'password_recovery.email_address': 'Email adress',
        'password_recovery.step_1_button': 'Reset password',
        'password_recovery.step_2_title': 'Reset password',
        'password_recovery.step_2_subtitle':
            'The instructions for resetting your password are sent to { userMail }',
        'password_recovery.step_2_link': 'Go back',
        'password_recovery.step_3_title': 'New password',
        'password_recovery.step_3_subtitle':
            'Your email has been verified. Please enter a new password.',
        'password_recovery.new_password': 'New password',
        'password_recovery.repeat_new_password': 'Re-enter new password',
        'password_recovery.step_3_button': 'Create new password',
        'password_recovery.step_4_title': 'Password changed',
        'password_recovery.step_4_button': 'Go to sign in',
        // ------------------------------------------------------------------------------------------------------------

        ...HOME.en,
    },
};
