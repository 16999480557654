import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import API from 'API';
import { setWsToken } from 'store/user/actions';
import { getUserAccessToken } from 'store/user/selector';

import { auth } from 'shared/utility';

const getWSToken = async (token, dispatch) => {
    // TODO Transfer to redux
    try {
        const response = await API.get(
            '/notifications/get_ws_token',
            auth(token)
        );

        dispatch(setWsToken(response.data.token));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while trying to get a token');
        // setErrorMessage(error.response && error.response.data.error);
    }
};

const TokenWrapper = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getUserAccessToken, shallowEqual);

    // Get new WS token on Access Token change
    useEffect(() => {
        if (token) {
            getWSToken(token, dispatch);
        }
    }, [token, dispatch]);

    return <>{props.children}</>;
};

export default TokenWrapper;
