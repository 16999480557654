import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'store/user/actions';

import styles from './Splash.module.scss';

class Splash extends Component {
    componentDidMount = () => {
        this.props.checkAuth();
    };

    render() {
        return <div className={styles.splash} />;
    }
}

const mapDispatchToProps = {
    checkAuth: () => actions.checkAuth(),
};

export default connect(null, mapDispatchToProps)(Splash);
