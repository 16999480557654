import * as constants from './constants';

const mock = {"data": [
        {
            "id": 9,
            "name": "Tata Steel Chess Masters",
            "site": "Wijk aan Zee, Netherlands",
            "start": "2019-04-03T22:00:00.000Z",
            "created": "2020-02-05T12:56:52.000Z",
            "categories": [
                {
                    "id": 7,
                    "name": "Main",
                    "rounds": [
                        {
                            "id": 7,
                            "name": "Round 6",
                            "start": "2019-04-03T22:00:00.000Z",
                            "games": [
                                {
                                    "id": 2,
                                    "variant": "standard",
                                    "start_fen": null,
                                    "time": 5400,
                                    "increment": 0,
                                    "round_id": 7,
                                    "white": {
                                        "name": "Carlsen, Magnus",
                                        "elo": 2872
                                    },
                                    "black": {
                                        "name": "Dubov, Daniil",
                                        "elo": 2683
                                    },
                                    "end": "in_progress",
                                    "winner": ""
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]};
const defaultSettings = {
    tournamentList: {data: []},
    inProgress: false,
};

const reducer = (state = defaultSettings, action) => {
    switch (action.type) {
        case constants.GET_TOURNAMENT_LIST_REQUEST:
            return {...state, inProgress: true};
        case constants.GET_TOURNAMENT_LIST_SUCCESS:
            return {...state, inProgress: false, tournamentList: action.tournamentList};
        case constants.GET_TOURNAMENT_LIST_FAILURE:
            return {...state, inProgress: false, err: action.err};
        default:
            return state;
    }
};

export default reducer;
