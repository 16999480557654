import * as constants from './constants';

export const getOTBTournamentListRequest = () => {
    return {
        type: constants.GET_TOURNAMENT_LIST_REQUEST
    };
};

export const getOTBTournamentListSuccess = (tournamentList) => {
    return {
        type: constants.GET_TOURNAMENT_LIST_SUCCESS,
        tournamentList
    };
};

export const getOTBTournamentListFailure = (err) => {
    return {
        type: constants.GET_TOURNAMENT_LIST_FAILURE,
        err
    };
};