import { PLAYER_COLOR, PLAYER_COLOR_SHORT_MAPPER } from 'store/game/constants';

export const getResultFromServerWinner = (winner) => {
    switch (winner) {
        case PLAYER_COLOR.WHITE:
            return { white: 1, black: 0 };
        case PLAYER_COLOR.BLACK:
            return { white: 0, black: 1 };
        default:
            return { white: 0.5, black: 0.5 };
    }
};

export const getPlayerWonPointsFromResult = (result, playerColor) => {
    return playerColor === PLAYER_COLOR.WHITE ? result : 1 - result;
};

function generate960StartPosition() {
    const rank = new Array(8),
        // randomizer (our die)
        d = function(num) {
            return Math.floor(Math.random() * ++num);
        },
        emptySquares = function() {
            const arr = [];
            for (let i = 0; i < 8; i++) {
                if (rank[i] === undefined) {
                    arr.push(i);
                }
            }
            return arr;
        };
    // place one bishop on any black square
    rank[d(2) * 2] = 'b';
    // place the other bishop on any white square
    rank[d(2) * 2 + 1] = 'b';
    // place the queen on any empty square
    rank[emptySquares()[d(5)]] = 'q';
    // place one knight on any empty square
    rank[emptySquares()[d(4)]] = 'n';
    // place the other knight on any empty square
    rank[emptySquares()[d(3)]] = 'n';
    // place the rooks and the king on the squares left, king in the middle
    for (let x = 1; x <= 3; x++) {
        rank[emptySquares()[0]] = x === 2 ? 'k' : 'r';
    }
    return rank.join('');
}

export function startPositionToFen() {
    const fenTemplate =
        'bbbbbbbb/pppppppp/8/8/8/8/PPPPPPPP/WWWWWWWW w KQkq 0 1';
    const startPosition = generate960StartPosition();
    return fenTemplate
        .replace('bbbbbbbb', startPosition)
        .replace('WWWWWWWW', startPosition.toUpperCase());
}

export const pgnListToMoves = (pgnList) => {
    const moves = [];
    if (pgnList) {
        for (let i = 1; i <= pgnList.length; i += 2) {
            const moveNum = Math.round(i / 2) - 1;
            moves[moveNum] = {
                white: pgnList[i - 1],
                black: pgnList[i],
            };
        }
    }

    return moves;
};

export const getTurnFromFen = (fen) => {
    const matched = fen.match(/ (w|b) /g);
    if (matched) {
        return PLAYER_COLOR_SHORT_MAPPER[matched[0].trim()];
    } else {
        return PLAYER_COLOR.WHITE;
    }
};

const pointsToEndResult = {
    0: 'lost',
    0.5: 'draw',
    1: 'won',
};
const pointsToEndResultSpectate = {
    0: 'Black won',
    0.5: 'Draw',
    1: 'White won',
};
const reasonConverter = (reason) => {
    if (reason === 'resign' || reason === 'resigned') {
        return 'resignation';
    }
    if (reason === 'mate') {
        return 'checkmate';
    }
    return reason;
};
export const generateEndGameMessage = (result, playerColor, isSpectate = false) => {
    let bestMoveString = '';

    if (result && result.accuracy) {
        const accuracy = parseInt(
           result.accuracy[playerColor]
        );
        if (!isNaN(accuracy)) {
            bestMoveString = 'Best moves: ' + accuracy.toFixed(2) + '%';
        }
    }
    let title = '';
    if (isSpectate) {
        title = pointsToEndResultSpectate[result.score[playerColor]];
    } else {
        title = 'You ' + pointsToEndResult[result.score[playerColor]] + '';
    }
    const message = [
        `Game ended by ${reasonConverter(result.reason)}`,
        bestMoveString,
    ];

    return [title, message];
};