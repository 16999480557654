import {
    call,
    all,
    put as dispatch,
    take,
    fork,
    cancel,
} from 'redux-saga/effects';

import { getWsTokenLS } from 'shared/localStorage';

import {
    createWebSocketConnection,
    listenForSocketMessages,
    listenForMessagesForSocket,
} from 'store/ws/connection';

import { WS_DISCONNECT } from './actionConstants';
import { USER_LOGOUT } from 'store/user/constants';
import {
    wsDisconnectSuccess,
    receiveWsAction,
    wsConnectionSuccess,
    wsUnexpectedDisconnect,
    wsConnectionRequest,
    wsConnectionFailure,
} from './actions';
import { runSaga } from 'store/root/store';
import { WS_SEND_ACTION } from 'store/gameWs/actionConstants';

export function* connectGameWs() {
    yield dispatch(wsConnectionRequest());
    const socket = yield call(createWebSocketConnection, {
        endpoint: process.env.REACT_APP_WS_ENDPOINT,
        token: getWsTokenLS()
    });

    if (!socket) {
        yield dispatch(wsConnectionFailure());
        return Promise.resolve(false);
    }

    // starts the task in the background
    const socketTask = yield fork(listenForSocketMessages, socket, {
        dispatchAction: receiveWsAction,
        connectionSuccess: wsConnectionSuccess,
        dispatchUnexpectedDisconnect: wsUnexpectedDisconnect,
    });

    // Connect to redux action listeners
    yield fork(listenForMessagesForSocket, socket, { action: WS_SEND_ACTION });

    // when DISCONNECT action is dispatched, we cancel the socket task
    yield all([take(USER_LOGOUT), take(WS_DISCONNECT)]);
    yield cancel(socketTask);
    yield dispatch(wsDisconnectSuccess());
}

// TODO Go trough redux
export function connectManually() {
    runSaga(connectGameWs);
}
