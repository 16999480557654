import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* OTBTournamentSaga() {
    yield takeLatest(constants.GET_TOURNAMENT_LIST_REQUEST, getOTBTournamentList);
}

function* getOTBTournamentList() {
    try {
        const response = yield api.getOTBTournamentList();
        yield dispatch(actions.getOTBTournamentListSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getOTBTournamentList failed', e);
        yield dispatch(actions.getOTBTournamentListFailure(e));
    }
}