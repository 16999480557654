import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Header from 'UI/Header/Header';

import styles from './Layout.module.scss';

const Layout = ({ children, location }) => {
    const [onboarding, setOnboarding] = useState(null);

    useEffect(() => {
        setOnboarding(
            location.pathname === '/onboarding' ||
                location.pathname === '/new-game'
        );
    }, [location.pathname]);

    return (
        <div className={onboarding ? styles.bg : styles.layout}>
            {location.pathname === '/home' ||
            location.pathname === '/playhub' ? null : (
                <Header show={onboarding} pathname={location.pathname} />
            )}

            <main className={styles.main}>{children}</main>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object,
};

export default withRouter(Layout);
