import { takeLatest, put as dispatch, all } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { redirect } from 'store/util/actions';

export function* userSaga() {
    yield takeLatest(constants.CHECK_AUTH, checkUserAuth);
    yield takeLatest(constants.LOGIN_REQUEST, loginRequest);
    yield takeLatest(constants.USER_LOGOUT, userLogout);
    yield takeLatest(constants.CHECK_AUTH_LOGOUT, userLogout);
    yield takeLatest(constants.REGISTER_REQUEST, registerRequest);
    yield takeLatest(constants.EMAIL_REGISTER_REQUEST, emailRegisterRequest);
    yield takeLatest(constants.FACEBOOK_LOGIN_REQUEST, facebookLoginRequest);
    yield takeLatest(constants.GOOGLE_LOGIN_REQUEST, googleLoginRequest);
    yield takeLatest(constants.GET_USER_REQUEST, userRequest);
    yield takeLatest(constants.CHECK_USERNAME_REQUEST, checkUsernameRequest);
    yield takeLatest(
        constants.GET_CHAT_AUTH_TOKEN_REQUEST,
        getChatAuthTokenRequest
    );
    yield takeLatest(
        constants.START_PASSWORD_RECOVERY_REQUEST,
        startPasswordRecovery
    );
    yield takeLatest(constants.RECOVER_PASSWORD_REQUEST, recoverPassword);
}

// TODO Bojan refresh token part of the flow
function* checkUserAuth({ accessToken, refreshToken }) {
    try {
        const params = {
            access: accessToken,
            refresh: refreshToken,
        };
        const response = yield api.userRequest(params.access);
        yield dispatch(actions.getUserSuccess(response.data.data));
        yield dispatch(redirect('/home'));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('checkUserAuth failed', e);
        yield dispatch(actions.logout(e));
    }
}

function* loginRequest({ data }) {
    try {
        const response = yield api.loginRequest(data);

        yield dispatch(actions.setAccessToken(response.data.data.access));
        yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.loginSuccess(response.data.data));
        yield dispatch(redirect('/home'));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        yield dispatch(actions.loginFailure(e));
    }
}

function* userLogout() {
    yield all([
        localStorage.removeItem('_k_sqr_at'),
        localStorage.removeItem('_k_sqr_rt'),
        localStorage.removeItem('_k_sqr_ws_t'),
        localStorage.removeItem('_k_sqr_cu'),
        localStorage.removeItem('usr_type'),
    ]);
    yield dispatch(redirect('/onboarding'));
    // window.location = '/onboarding';
}

function* registerRequest({ data, flag }) {
    try {
        const response = yield api.registerRequest(data);
        switch (flag) {
            case 'facebook':
                yield dispatch(
                    actions.facebookLoginRequest({
                        access_token: data.facebook_token,
                    })
                );
                break;
            case 'google':
                yield dispatch(
                    actions.googleLoginRequest({
                        id_token: data.google_token,
                    })
                );
                break;

            default:
                break;
        }
        yield dispatch(actions.registerSuccess(response.data.data));
        if (!flag) {
            yield dispatch(redirect('/login'));
        }
    } catch (e) {
        //eslint-disable-next-line
        console.warn('registerRequest failed', e);
        yield dispatch(actions.registerFailure(e));
    }
}

function* emailRegisterRequest({ data }) {
    try {
        const response = yield api.emailRegisterRequest(data);
        yield dispatch(actions.emailRegisterSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('emailRegisterRequest failed', e);
        yield dispatch(actions.emailRegisterFailure(e));
    }
}

function* facebookLoginRequest({ data }) {
    try {
        const response = yield api.facebookLoginRequest(data);
        yield dispatch(actions.setAccessToken(response.data.data.access));
        yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.facebookLoginSuccess(response.data.data));
        yield dispatch(redirect('/home'));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('facebookLoginRequest failed', e);
        yield dispatch(actions.facebookLoginFailure(e));
    }
}

function* googleLoginRequest({ data }) {
    try {
        const response = yield api.googleLoginRequest(data);
        yield dispatch(actions.setAccessToken(response.data.data.access));
        yield dispatch(actions.setRefreshToken(response.data.data.refresh));
        yield dispatch(
            actions.getChatAuthTokenRequest(response.data.data.access)
        );
        yield dispatch(actions.googleLoginSuccess(response.data.data));
        yield dispatch(redirect('/home'));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('googleLoginRequest failed', e);
        yield dispatch(actions.googleLoginFailure(e));
    }
}

function* userRequest({ options }) {
    try {
        const response = yield api.userRequest(options);
        yield dispatch(actions.getUserSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        yield dispatch(actions.getUserFail(e));
    }
}

function* checkUsernameRequest({ username }) {
    try {
        const response = yield api.usernameCheckRequest(username);
        yield dispatch(actions.checkUsernameSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('usernameCheckRequest failed', e);
        yield dispatch(actions.checkUsernameFailure(e));
    }
}

function* startPasswordRecovery({ data }) {
    try {
        const response = yield api.startPasswordRecovery(data);
        yield dispatch(actions.startPasswordRecoverySuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('startPasswordRecovery failed', e);
        yield dispatch(actions.startPasswordRecoveryFailure(e));
    }
}

// TODO Bojan: direct login? (response contains access and refresh tokens)
function* recoverPassword({ data }) {
    try {
        const response = yield api.recoverPassword(data);
        yield dispatch(actions.recoverPasswordSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('recoverPassword failed', e);
        yield dispatch(actions.recoverPasswordFailure(e));
    }
}

function* getChatAuthTokenRequest({ options }) {
    try {
        const response = yield api.chatAuthTokenRequest(options);
        yield dispatch(
            actions.chatAuthTokenRequestSuccess(response.data.data.token)
        );
    } catch (e) {
        //eslint-disable-next-line
        console.warn('loginRequest failed', e);
        // yield dispatch(actions.chatAuthTokenRequestFail(e));
    }
}
