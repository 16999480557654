import * as gameConstants from './constants';
import { saveGameSettingsLS, getGameSettingsLS } from 'shared/localStorage';

const localStorageSettings = getGameSettingsLS();
const defaultSettings = {
    playerVsComputer: {
        color: gameConstants.PLAYER_COLOR.WHITE,
        level: gameConstants.ENGINE_LEVEL.LEVEL1,
        gameType: gameConstants.GAME.STANDARD,
        time: gameConstants.GAME_TYPE.BLITZ_5_0,
    },
    playerVsOpponent: {
        gameType: gameConstants.GAME.STANDARD,
        time: gameConstants.GAME_TYPE.BLITZ_5_0,
        rated: true,
    },
    playerVsFriend: {
        color: gameConstants.PLAYER_COLOR.BLACK,
        gameType: gameConstants.GAME.STANDARD,
        time: gameConstants.GAME_TYPE.BLITZ_5_0,
        rated: false,
    },
};

const initialState = {
    settings: localStorageSettings ? localStorageSettings : defaultSettings,
};

const _getSettingsType = (gameType) => {
    let settingsType = 'playerVsComputer';
    if (gameType === 'VS_FRIEND') {
        settingsType = 'playerVsFriend';
    } else if (gameType === 'VS_OPPONENT') {
        settingsType = 'playerVsOpponent';
    }

    return settingsType;
};

const setVariantGame = (state, { game, gameType }) => {
    const settingsType = _getSettingsType(gameType);

    const newSettings = {
        ...state.settings,
        [settingsType]: {
            ...state.settings[settingsType],
            gameType: game,
        },
    };

    saveGameSettingsLS(newSettings);

    return {
        ...state,
        settings: newSettings,
    };
};

const setTimeControl = (state, { time, gameType }) => {
    const settingsType = _getSettingsType(gameType);

    const newSettings = {
        ...state.settings,
        [settingsType]: {
            ...state.settings[settingsType],
            time: time,
        },
    };

    saveGameSettingsLS(newSettings);

    return {
        ...state,
        settings: newSettings,
    };
};

const setDifficultyLevel = (state, { level }) => {
    const newSettings = {
        ...state.settings,
        playerVsComputer: {
            ...state.settings.playerVsComputer,
            level: level,
        },
    };

    saveGameSettingsLS(newSettings);

    return {
        ...state,
        settings: newSettings,
    };
};

const setColor = (state, { color, gameType }) => {
    const settingsType = _getSettingsType(gameType);

    const newSettings = {
        ...state.settings,
        [settingsType]: {
            ...state.settings[settingsType],
            color: color,
        },
    };

    saveGameSettingsLS(newSettings);

    return {
        ...state,
        settings: newSettings,
    };
};

const setPredefinedFen = (state, {fen, gameType}) => {
    const settingsType = _getSettingsType(gameType);

    const newSettings = {
        ...state.settings,
        [settingsType]: {
            ...state.settings[settingsType],
            predefinedFen: fen,
        },
    };

    saveGameSettingsLS(newSettings);

    return {
        ...state,
        settings: newSettings,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case gameConstants.SET_VARIANT_TYPE:
            return setVariantGame(state, action);
        case gameConstants.SET_TIME_CONTROL:
            return setTimeControl(state, action);
        case gameConstants.SET_DIFFICULTY_LEVEL:
            return setDifficultyLevel(state, action);
        case gameConstants.SET_PIECES_COLOR:
            return setColor(state, action);
        case gameConstants.SET_PREDEFINED_FEN:
            return setPredefinedFen(state, action);
        default:
            return state;
    }
};

export default reducer;
