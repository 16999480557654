import { combineReducers } from 'redux';
import utilReducer from 'store/util/reducer';
import userReducer from 'store/user/reducer';
import localeReducer from 'store/locale/reducer';
import gameReducer from 'store/game/reducer';
import gameWsReducer from 'store/gameWs/reducer';
import tournamentReducer from 'store/otbTournament/reducer';

// Combine reducers for redux store
const rootReducer = combineReducers({
    user: userReducer,
    locale: localeReducer,
    util: utilReducer,
    game: gameReducer,
    gameWs: gameWsReducer,
    tournament: tournamentReducer,
});

export default rootReducer;
